<template>
  <v-container id="404" class="text-center" style="height:600px">
    <div class="display-4 purple--text font-weight-bold">404</div>
    <p>Sorry, the page your looking for has not been created yet. </p>
    <v-btn @click="$router.go(-1)" text color="purple">
        <v-icon left>mdi-arrow-left</v-icon>
        Go back
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: '404'
}
</script>

<style>

</style>